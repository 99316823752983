




import { Component, Vue } from 'vue-property-decorator'
import ClientForm from './components/ClientForm.vue'

@Component({
  name: 'CreateClient',
  components: {
    ClientForm
  }
})
export default class extends Vue {}
